import * as React from "react"
import Link from "../components/link"
import { GatsbyImage } from "gatsby-plugin-image"
import AnimateInView from "../components/animation/animateInView"

function animation(inView, intersectionObserver) {
    if (intersectionObserver?.target) {
        if(inView){
         intersectionObserver.target.classList.add('in-view')
        } else{
         intersectionObserver.target.classList.remove('in-view')
        }
    }
}

const projectBanner = ({image, title, slug}) => {
  return (
  <Link to={'/'+slug} className="hover--zoom ">
    <div className="project--banner h-66vw max-100vh overflow-hidden bg-grey text-white no-pointer m-ratio-2-1 m-mh-100vh ">
        <p className="h1 title m-mb60 pos-rel make-layer">{title}</p>
        <AnimateInView  animation={animation} className="will-zoom image--zoom bg-image">
          <GatsbyImage className='project-image bg-image' image={image.gatsbyImageData} alt={title} />
        </AnimateInView>
    </div>    
  </Link> 
  )
}
export default projectBanner
 