import * as React from "react"
import Spacer from "../components/spacer"
import { GatsbyImage } from "gatsby-plugin-image"
const ImageBanner = ({title, content, author,spacer,image}) => {
  const scrollBottom = () =>  { 
    window.scrollTo(0,document.body.scrollHeight)
  } 

  return(
  <div className="text-banner p20"> 
    <Spacer/>
    <p className="h3 m0 mb40 black ma mt max-900 text-center">{title}</p>
    <div className="max-900 ma">
      <GatsbyImage image={image.gatsbyImageData} />
    </div>
    <div className="max-600 ma text-center">
      <div className="small-spacer"/>
      <div className="copy large mb30">
        {content} 
      </div>
      <div className="flex align-center justify-center  ">
          <p className="medium mr20">To find out more</p><p role='presentation' onClick={(event) => scrollBottom(event)} onKeyDown={(event) => scrollBottom(event)} className="button ">Contact us</p>
      </div>
    </div>
     
  </div>)
}
export default ImageBanner
