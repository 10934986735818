import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

export default function AnimateInView({animation = () => {}, options = {} ,children,...props  }) {

    const { ref, inView, entry } = useInView(options);

    useEffect(() =>{
        animation(inView,entry)
    },[inView, animation, entry])

    return (<div ref={ref} {...props}>
        {children}
    </div>);
}
