import  React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import CurrentProjects from "../components/projects" 
import SEO from "../components/seo"
import { graphql } from "gatsby" 
import ImageBanner from "../components/imageBanner"

class ProjectsPage extends React.Component {
	render() {
			var data = this.props.data.allDatoCmsProject.nodes;
			var projectsPage = this.props.data.datoCmsCurrentProjectsPage;
	    return (
		  <Layout internal={this.props.location.state?.internal}>
		   	<SEO title="Atlantis Property Group" /> 
		   	<Header />
		   	<CurrentProjects projects={data}/>
			<ImageBanner image={projectsPage.image} title={projectsPage.currentProjectsInfoTitle} content={projectsPage.currentProjectsInfo} />
		  </Layout>
		)
	} 
}

export default ProjectsPage

export const query = graphql`
	fragment Project on DatoCmsProject{
		 title
	      slug
	      image {
	      	gatsbyImageData
	      }
	}
  query ProjectsQuery {
	datoCmsCurrentProjectsPage {
		currentProjectsInfo
		currentProjectsInfoTitle
		image{
			gatsbyImageData
		}
  	}
  	allDatoCmsProject(filter: {currentProject: {eq: true}}) { 
	    nodes {
	      ...Project
	    }
	  } 
  }
`
