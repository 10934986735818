import * as React from "react"
import PropTypes from "prop-types"
import Link from "../components/link"

const Header = ({ siteTitle }) => {
  const onClick = () =>  { 
    document.querySelector('.side--menu').classList.add('active');
    document.querySelector('.menu--background ').classList.add('active');
  } 
  const scrollBottom = () =>  { 
    window.scrollTo(0,document.body.scrollHeight)
  } 

  return (
    <header className="masthead flex">
      <div className="flex p20 w-100 max-1600 ma"> 
        <Link to="/" className="flex flex-column justify-center">
          <div className="main--logo"></div>
        </Link>
        <div className="buttons mla flex">
          <p className="button white m0 mr10 m-hide"  role='presentation' onClick={(event) => scrollBottom(event)} onKeyDown={(event) => scrollBottom(event)}>Enquire</p>
          <p className="button white m0"  role='presentation' onClick={(event) => onClick(event)} onKeyDown={(event) => onClick(event)}>Menu</p>
        </div>
      </div>
    </header>
  ) 
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
