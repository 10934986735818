import React from "react"
import ProjectBanner from "../components/projectBanner"

const Banners = ({projects}) => {
  return (
    <div>
      {projects.map((project, index) => {
        return (
        <ProjectBanner image={project.image} title={project.title} slug={project.slug} />
        )
      })}
    </div>)
}

const Projects = ({ projects }) => {
  return (
    <div className="current--projects">
       <Banners projects={projects}/>
    </div>
  )
}
export default Projects